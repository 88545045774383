#support-bg {
    background-color: rgb(255,247,242);
    height: 1280px;
    color: rgb(11,64,58);
}

#support-img {
    display: block;
    margin: 0 auto;
    width: 80%;
}

.support-bg-white {
    position: relative;
    background-color: white;
    display: block;
    margin: -544px auto 0 auto;
    width: 800px;
    height: 550px;
}

.support-circle {
    position: relative;
    top: -95px;
    background-color: white;
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

#support-logo {
    position: relative;
    top: 15px;
    height: 120px;
    display: block;
    margin: 0 auto;
}

.support-info {
    justify-content: center;
    text-align: center;
}

.support-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 15px;
}

.support-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 20px;
    margin-bottom: 40px;
}

#donate-button {
    background-color: rgb(236,146,15);
    color: white;
    font-family: 'Merriweather Sans', sans-serif !important;
    font-weight: bold;
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 175px;
    height: 55px;
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    #support-bg {
        height: 1000px;
    }

    .support-bg-white {
        width: 880px;
        margin: -544px auto 0 auto;
    }
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    #support-bg {
        height: 752px;
    }

    #support-img {
        visibility: hidden;
    }

    .support-bg-white {
        margin: -544px auto 0 auto;
        width: 750px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    #support-bg {
        height: 740px;
    }

    #support-logo {
        height: 110px;
    }

    .support-circle {
        top: -75px;
        width: 140px;
        height: 140px;
    }
    
    .support-bg-white {
        width: 420px;
        height: 630px;
        margin: -360px auto 0 auto;
    }

    .support-heading {
        font-size: 50px;
        margin-bottom: 15px;
    }

    .support-subheading {
        font-size: 20px;
        margin-bottom: 40px;
    }

    #donate-button {
        font-size: 20px;
        padding-top: 12px;
        width: 175px;
        height: 55px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #support-bg {
        height: 920px;
    }

    #support-img {
        display: none;
    }

    #support-logo {
        height: 96px;
    }

    .support-circle {
        top: -75px;
        width: 130px;
        height: 130px;
    }

    .support-bg-white {
        top: 380px;
        margin: -340px auto 0 auto;
        width: 352px;
        height:  470px;
    }
    
    .support-heading {
        font-size: 32px;
        margin-bottom: 40px;
    }

    #donate-button {
        font-size: 16px;
        padding-top: 15px;

    }
}

