#navbar {
    background-color: rgb(1,52,72) !important;
    padding: 10px 0
}

#responsive-navbar-nav {
    background-color: rgb(1,52,72) !important
}

#nlac {
    font-family: 'Playfair Display', serif;
    font-size: 23px;
    font-weight: bold;
    color: rgb(236,146,15);
    margin-right: 50px;
    margin-left: 120px
}

#alliance-logo {
    height: 75px;
    padding-right: 10px;
}

.navlink {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: whitesmoke;
    margin: 0 5px;
}

.navlink-drop {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: whitesmoke;
    margin: 0 5px;
}

.navlink-dropitem {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: whitesmoke !important;
    background-color: rgb(1,52,72) !important;
    padding: 10px 15px !important;
}

a :hover {
    color: rgb(236,146,15) !important;
}

/* Desktops, large screens Viewport */
@media only screen and (max-width: 1200px) {
    #nlac {
        margin-left: 0px;
    }
}

/* Small screens, laptops Viewport */
@media only screen and (max-width: 1024px) {
    .navlink-drop {
        left: 6px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    #nlac {
        margin-right: 0px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #nlac {
        font-size: 18px;
    }

    .navlink-drop {
        margin: 0 0;
    }
}
