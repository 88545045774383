#lg-bg {
    background-color: rgb(255,247,242);
    height: 2000px;
    color: rgb(11,64,58);
    font-family: 'Merriweather Sans', sans-serif;
}

.lg-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 16px 0 16px;
}

.lg-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 65px;
    padding-bottom: 50px;
    color: rgb(11,64,58)
}

.lg-group {
    padding: 0 20%;
    margin-bottom: 128px;
}

.lg-subheading {
    font-size: 35px;
    margin-bottom: 50px;
} 

.lg-CCC {
    font-weight: bold;
    margin-bottom: 80px;
}

.lg-hc {
    text-align: left;
}

.lg-hc h4 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 16px;
}

.lg-hc h3 {
    font-size: 22px;
}

.lg-hc p {
    font-size: 18px;
    margin-bottom: 50px;
}

.lg-verse {
    font-size: 18px;
    margin-bottom: 50px;
    font-style: italic;
}

.lg-hc ul li {
    font-size: 18px;
}

.lg-contact {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 16px;
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    #lg-bg {
        height: 2100px;
    }

    .lg-group {
        padding: 0 15%;
    }
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    #lg-bg {
        height: 2000px;
    }

    .lg-subheading {
        font-size: 30px;
    } 

    .lg-hc h4 {
        font-size: 20px;
    }

    .lg-hc h3 {
        font-size: 18px;
    }

    .lg-hc p {
        font-size: 16px;
        margin-bottom: 50px;
    }

    .lg-group {
        padding: 0 10%;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    #lg-bg {
        height: 2100px;
    }

    .lg-CCC {
        font-size: 20px;
        font-weight: 500;
    }

    .lg-group {
        padding: 0 5%;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #lg-bg {
        height: 2400px;
    }

    .lg-content{
        padding: 80px 16px 0 16px;
    }
    
    .lg-heading {
        font-size: 35px;
    }

    .lg-group {
        padding: 0 1%;
    }
    
    .lg-subheading {
        font-size: 28px;
        font-weight: 500;
    }
    
    .lg-CCC {
        font-size: 18px;
        margin-bottom: 50px;
    }

    .lg-hc h4 {
        font-size: 18px;
    }

    .lg-hc p {
        font-size: 16px;
        margin-bottom: 50px;
    }

    .lg-hc h3 {
        font-size: 20px;
    }

    ul li {
        font-size: 16px;
    }

    .lg-hc ul li {
        font-size: 16px;
    }

    .lg-contact {
        font-size: 18px;
        line-height: 30px;
    }
}
