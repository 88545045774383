#connect-bg {
    background-color: whitesmoke;
    height: 1088px;
    color: rgb(11,64,58);
}

.connect-content{
    text-align: center;
    padding: 120px 24px 0 24px;
}

#connect-logo {
    height: 120px;
    margin-bottom: 24px;
}

.connect-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 55px;
    padding-bottom: 16px;
}

.connect-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
    color: lightgray;
}

.connect-container {
    display: inline-flex;
    vertical-align: middle;
    width: 70%;
    margin: 0 10%;
}

.connect-item1 {
    width: 480px;
    padding: 112px 16px;
    background-color: lightgray;
}

.connect-item2 {
    width: 480px;
    padding: 112px 16px;
    background-color: rgb(229,78,76);
}

.connect-item3 {
    width: 480px;
    padding: 112px 16px;
    background-color: rgb(59,111,152);
}

#connect-icon {
    height: 96px;
    margin-bottom: 48px;
}

.connect-subinfo {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 20px;
}

.connect-container a {
    text-decoration: none;
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    #connect-bg {
        height: 1680px;
    }

    .connect-container {
        display: inline-block;
        padding: 0 10%;
    }

    .connect-item1 {
        padding: 80px 16px;
    }
    
    .connect-item2 {
        padding: 80px 16px;
    }
    
    .connect-item3 {
        padding: 80px 16px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    .connect-container {
        padding: 0 0 !important;
    }

    .connect-item1 {
        width: 400px;
        padding: 80px 1%;
    }
    
    .connect-item2 {
        width: 400px;
        padding: 80px 1%;
    }
    
    .connect-item3 {
        width: 400px;
        padding: 80px 1%;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #connect-bg {
        height: 1400px;
    }

    .connect-content{
        padding: 80px 1% 0 1%;
    }

    #connect-logo {
        height: 96px;
    }
    
    .connect-heading {
        font-size: 35px;
    }

    .connect-subheading {
        font-size: 30px;
    }

    .connect-container {
        width: auto !important;
    }

    .connect-item1 {
        width: 300px;
        padding: 80px 1%;
    }
    
    .connect-item2 {
        width: 300px;
        padding: 80px 1%;
    }
    
    .connect-item3 {
        width: 300px;
        padding: 80px 1%;
    }

    #connect-icon {
        height: 64px;
        margin-bottom: 48px;
    }
    
    .connect-subinfo {
        font-family: 'Merriweather Sans', sans-serif;
        font-size: 18px;
        font-weight: 600;
    }
}

