#online-bg {
    background-color: rgb(255,247,242);
    height: 730px;
    color: rgb(11,64,58);
}

.online-content{
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    padding: 120px 16px 0 16px;
}

#online-logo {
    height: 120px;
    margin-bottom: 24px;
}

.online-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 65px;
    padding-bottom: 16px;
}

.online-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 20px;
    line-height: 50px;
}

p a {
    color: rgb(236,146,15);
}
p a:hover {
    color: rgb(11,64,58) !important;
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    .online-content{
        line-height: 90px;
        padding: 112px 16px 0 16px;
    }

    .online-heading {
        font-size: 60px;
        padding-bottom: 20px;
    }

    .online-subheading {
        font-size: 16px;
        line-height: 50px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #online-bg {
        height: 600px;
    }

    .online-content{
        padding: 80px 16px 0 16px;
    }

    #online-logo {
        height: 96px;
    }
    
    .online-heading {
        font-size: 35px;
    }
    
    .online-subheading {
        font-size: 16px;
        line-height: 35px;
    }
}
