#about-bg {
    background-color: rgb(255,247,242);
    height: 3000px;
    color: rgb(11,64,58);
}

.about-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 24px 0 24px;
}

#about-logo {
    height: 120px;
    margin-bottom: 24px;
}

.about-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 55px;
    padding-bottom: 16px;
}

.about-VVP {
    display: inline-flex;
    margin: 40px 5% 96px 5%;
}

.about-item {
    width: 33%;
}

.about-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 16px;
    color: rgb(236,146,15);
}

.about-subinfo {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 20px;
}

.nlac-story {
    margin: 0 8%;
}

.nlac-story p {
    font-size: 20px;
}

.nlac-story h4 {
    margin: 25px 0 16px 0;
    color: rgb(236,146,15);
}

/* Desktops, large screens Viewport */
@media only screen and (max-width: 1200px) {
    #about-bg {
        height: 3300px;
    }

    .about-VVP {
        display: block;
        margin: 0 20%
    }

    .about-item {
        width: 100%;
        margin-bottom: 50px;
    }

    .about-subheading {
        margin-bottom: 16px;
    }

    .nlac-story {
        margin: 0 5%;
    }
}

/* Small screens, laptops Viewport */
@media only screen and (max-width: 1024px) {
    #about-bg {
        height: 4000px;
    }

    .nlac-story {
        margin: 0 2%;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    #about-bg {
        height: 5000px;
    }

    .about-heading {
        font-size: 60px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #about-bg {
        height: 4400px;
    }

    .about-content{
        padding: 80px 2% 0 2%;
    }

    #about-logo {
        height: 96px;
    }
    
    .about-heading {
        font-size: 35px;
        margin-bottom: 25px;
    }

    .about-VVP {
        margin: 0 2%
    }

    .about-subheading {
        width: auto !important;
        font-size: 30px;
        margin-bottom: 0.5em;
    }

    .about-subinfo {
        font-size: 16px;
    }

    .nlac-story p {
        font-size: 16px;
    }
}

