#giving-bg {
    background-color: rgb(255,247,242);
    height: 1200px;
    color: rgb(11,64,58);
}

.giving-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 10% 0 10%;
}

#giving-logo {
    height: 120px;
    margin-bottom: 24px;
}

.giving-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 55px;
    padding-bottom: 16px;
}

.interac-container {
    display: inline-flex;
    text-align: left;
    margin-bottom: 96px;
}

.giving-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 18px;
    text-align: left;
}

.giving-content h2 {
    margin-bottom: 25px;
}
.giving-content p {
    margin-bottom: 20px;
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    #giving-bg {
        height: 1200px;
    }
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    #giving-bg {
        height: 1400px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    #giving-bg {
        height: 1650px;
    }

    .giving-content{
        text-align: center;
        vertical-align: middle;
        padding: 144px 5% 0 5%;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #giving-bg {
        height: 2000px;
    }

    .giving-content{
        padding: 80px 5% 0 5%;
    }

    #giving-logo {
        height: 96px;
    }
    
    .giving-heading {
        font-size: 35px;
    }

    #interac {
        height: 110px;
        margin-right: 15px;
    }

    .interac-container {
        margin-bottom: 20px;
    }
    
    .giving-subheading {
        font-size: 18px;
    }
}
