#staff-bg {
    background-color: rgb(255,247,242);
    height: 1250px;
    color: rgb(11,64,58);
}

.staff-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 24px 0 24px;
}

#staff-logo {
    height: 120px;
    margin-bottom: 24px;
}

.staff-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 55px;
    padding-bottom: 16px;
}

.staff-card {
    display: inline-flex;
    margin: 40px 5% 96px 5%;
}

.staff-individual {
    width: 50%;
    background-color: rgb(255,255,255);
    padding: 30px;
    margin: 25px;
}

.staff-title {
    font-family: 'Merriweather Sans', sans-serif;
    color: rgb(236,146,15);
    font-size: 20px;
}

.staff-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 37px;
    color: rgb(236,146,15);
}

.staff-subinfo {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 18px;
}

/* Desktops, large screens Viewport */
@media only screen and (max-width: 1200px) {
    #staff-bg {
        height: 1500px;
    }

    .staff-card {
        display: block;
        justify-content: center;
        width: 90%;
    }

    .staff-individual {
        width: 100% !important;
        margin: 30px;
    }
}

/* Small screens, laptops Viewport */
@media only screen and (max-width: 1024px) {
    #staff-bg {
        height: 1680px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    #staff-bg {
        height: 2000px;
    }

    .staff-heading {
        font-size: 60px;
    }

    .staff-individual {
        width: 90% !important;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #staff-bg {
        height: 1900px;
    }

    .staff-content{
        padding: 80px 1% 0 1%;
    }

    #staff-logo {
        height: 96px;
    }

    .staff-card {
        margin: 40px 1% 96px 1%;
    }

    .staff-individual {
        padding: 20px 5px 5px 5px;
    }
    
    .staff-heading {
        font-size: 35px;
    }

    .staff-subheading {
        width: auto !important;
        font-size: 30px;
    }

    .staff-subinfo {
        font-size: 16px;
    }
}

