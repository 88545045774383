#footer-bg {
    background-color: rgb(1,52,72);
    height: 135px;
}

.footer-content {
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    line-height: normal;
    padding: 25px 16px 0 16px;
}

.footer-nlac {
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    font-weight: bold;
    color: rgb(236,146,15);
}

#footer-alliance-logo {
    height: 90px;
    margin-right: 20px;
}

.footer-info {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: rgb(236,146,15);
} 

.footer-report{
    font-size: 12px;
}

.footer-report a:hover {
    color: goldenrod !important;

}
  

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #footer-bg {
        background-color: rgb(1,52,72);
        height: 110px;
    }

    #footer-alliance-logo {
        height: 50px;
        margin-right: 20px;
    }

    .footer-nlac {
        font-size: 20px;
    }
    
    .footer-info {
        font-size: 13px;
    } 
}
