#schedule-bg {
    background-color: rgb(255,255,255);
    height: 1000px;
    color: rgb(11,64,58);
}

.schedule-content{
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    line-height: normal;
    padding: 140px 8px 0 8px;
}

#schedule-logo {
    height: 120px;
    margin-bottom: 16px;
}

.schedule-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 80px;
    padding-bottom: 10px;
}

.schedule-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 23px;
    padding-bottom: 50px;
    color: rgb(236,146,15);
}

.schedule-campus {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 50px;
    padding-bottom: 30px;
}

.schedule-info {
    display: inline-flex;
}

.schedule-location {
    text-align: left;
    padding-right: 50px;
    width: 50%;
    color: rgb(236,146,15);
}

.schedule-location h4 {
    color: rgb(11,64,58);
    padding-bottom: 25px;
}

.schedule-time {
    text-align: left;
    padding-left: 50px;
    width: 50%;
    color: rgb(236,146,15);
}

.schedule-time h4 {
    color: rgb(11,64,58);
    padding-bottom: 25px;
}

.bold {
    font-weight: bold;
}

.bold a {
    color: rgb(236,146,15);
}
.bold a :hover {
    color: rgb(11,64,58) !important;
}

.vertical-line {
    border-left: thick solid rgb(236,146,15);
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    .schedule-content{
        padding: 130px 8px 0 8px;
    }

    #schedule-logo {
        height: 110px;
    }

    .schedule-heading {
        font-size: 60px;
        padding-bottom: 10px;
    }

    .schedule-subheading {
        font-size: 20px;
        padding-bottom: 50px;
    }

    .schedule-info {
        padding: 0 1em;
    }

    .schedule-location {
        padding-right: 20px;
    }
    
    .schedule-time {
        padding-left: 20px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #schedule-bg {
        height: 912px;
    }

    .schedule-content{
        padding: 92px 16px 0 16px;
    }

    #schedule-logo {
        height: 96px;
    }
    
    .schedule-heading {
        font-size: 32px;
        padding-bottom: 20px;
    }
    
    .schedule-subheading {
        font-size: 20px;
        font-weight: 500;
        padding: 0 8px 20px 8px;
    }

    .schedule-info {
        display: block;
    }

    .schedule-location {
        width: 300px;
        padding-right: 0 !important;
        padding-bottom: 20px;
        color: rgb(236,146,15);
    }

    .schedule-location h4 {
        padding-bottom: 10px;
    }
    
    .schedule-time {
        width: 300px;
        padding-left: 0 !important;
        padding-top: 20px;
        color: rgb(236,146,15);
    }

    .schedule-time h4 {
        padding-bottom: 10px;
    }

    #vertical-line {
        border-bottom: thick solid rgb(236,146,15);
    }
}
