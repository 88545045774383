#statement-bg {
    background-color: rgb(255,247,242);
    height: 928px;
    color: rgb(11,64,58);
}

.statement-content{
    text-align: center;
    vertical-align: middle;
    padding: 160px 0 80px 0;
}

/* Statement Top Lines */
.statement-heading {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

#horizontal-line {
    width: 300px;
    border-top: thick solid rgb(236,146,15);
}

#statement-logo {
    height: 120px;
    margin: 0 16px 16px 16px;
}

.statement-info {
    font-family: 'Playfair Display', serif;
    height: 300px;
    margin: 50px 250px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.statement-info h2 {
    font-size: 25px;
}

/* Statement Bottom Lines */
.statement-heading-1 {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

#horizontal-line-1 {
    width: 500px;
    border-top: thick solid rgb(236,146,15);
}

.statement-nlac {
    color: rgb(236,146,15);
    font-size: 24px;
    font-weight: 500;
    margin: 0 16px;
}

#horizontal-line-2 {
    width: 160px;
    border-top: thick solid rgb(236,146,15);
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    .statement-info {
        margin: 50px 150px;
    }

    #horizontal-line {
        width: 230px;
    }

    #horizontal-line-1 {
        width: 400px;
    }

    #horizontal-line-2 {
        width: 120px;
    }
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    .statement-info {
        margin: 50px 100px;
    }

    #horizontal-line {
        width: 170px;
    }

    #horizontal-line-1 {
        width: 300px;
    }

    #horizontal-line-2 {
        width: 100px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    .statement-info {
        margin: 50px 20px;
    }

    #horizontal-line {
        width: 60px;
    }

    #statement-logo {
        height: 110px;
    }

    #horizontal-line-1 {
        width: 70px;
    }

    #horizontal-line-2 {
        width: 70px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #statement-bg {
        height: 750px;
    }

    .statement-content{
        padding: 100px 0 80px 0;
    }

    .statement-info {
        margin: 50px 20px;
    }

    #statement-logo {
        height: 96px;
    }

    #horizontal-line {
        width: 110px;
    }

    .statement-nlac {
        font-size: 24px;
        font-weight: 500;
    }

    #horizontal-line-1 {
        width: 176px;
    }

    #horizontal-line-2 {
        width: 80px;
    }
}
