#background {
    background-image: url(../../image/north-vancouver.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 1040px;
    opacity: 0.95;
}

.welcome  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 880px;
    padding-top: 160px;
}

.heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 130px;
    color: rgb(236,146,15);
    text-shadow: 5px 7px 0px rgba(87, 90, 94, 0.5);
}

.sub-heading {
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: aliceblue;

}

/* Desktops, large screens Viewport */
@media only screen and (max-width: 1200px) {
}

/* Small screens, laptops Viewport */
@media only screen and (max-width: 1024px) {
    .heading {
        font-size: 100px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 768px) {
    .heading {
        font-size: 70px;
    }

    .sub-heading {
        font-size: 30px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 480px) {
    #background{
        height: 600px;
    }

    .welcome {
        padding-top: 0 !important;
        padding-bottom: 200px;
    }

    .heading {
        font-size: 40px;
        text-shadow: 3px 3px 0px rgba(87, 90, 94, 0.5);
    }
    
    .sub-heading {
        font-size: 18px;
    }
}

