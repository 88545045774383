#mission-bg {
    background-color: rgb(255,247,242);
    height: 4200px !important;
    color: rgb(11,64,58);
    font-family: 'Merriweather Sans', sans-serif;
}

.mission-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 16px 0 16px;
}

.mission-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 65px;
    padding-bottom: 50px;
    color: rgb(11,64,58)
}

.mission-group {
    padding: 0 20%;
    margin-bottom: 100px;
}

.mission-statement {
    text-align: left;
}

.mission-statement p {
    font-size: 20px;
    margin-bottom: 50px;
}

.mission-verse {
    font-size: 18px;
    margin-bottom: 100px;
    font-style: italic;
}

.mission-statement ul li {
    font-size: 20px;
}

.space  {
    margin-top: 100px;
}

.iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-top: 100px;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 96%;
}

.mission-contact {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 20px;
}

#info-button {
    background-color: rgb(236,146,15);
    color: white;
    font-family: 'Merriweather Sans', sans-serif !important;
    font-weight: bold;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 175px;
    height: 55px;
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    .mission-group {
        padding: 0 15%;
    }

    #mission-bg {
        height: 4600px;
    }

}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    .mission-group {
        padding: 0 10%;
    }

    #mission-bg {
        height: 4100px;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    .mission-group {
        padding: 0 5%;
    }

    #mission-bg {
        height: 5100px;
    }

    .mission-statement p {
        font-size: 1.5em;
        margin-bottom: 3em;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #mission-bg {
        height: 1800px;
    }

    .mission-content{
        padding: 80px 16px 0 16px;
    }
    
    .mission-heading {
        font-size: 35px;
    }

    .mission-verse {
        font-size: 18px;
        margin-bottom: 80px;
    }

    .mission-group {
        padding: 0 1%;
    }
    
    .mission-statement p {
        font-size: 18px;
        margin-bottom: 30px;
    }
    
    .lg-contact {
        font-size: 16px;
    }
}
