#prayer-bg {
    background-color: rgb(255,247,242);
    height: 850px;
    color: rgb(11,64,58);
}

.prayer-content{
    text-align: center;
    vertical-align: middle;
    padding: 120px 16px 0 16px;
}

.prayer-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 65px;
    padding-bottom: 16px;
}

.prayer-info {
    font-size: 20px;
}

.prayer-subheading {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 25px;
    margin: 3% 0 1% 0;
} 

.prayer-group {
    display: inline-flex;
    text-align: left;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0.5%;
    margin: 1% 0;
}

.prayer-group ul li {
    font-size: 20px;
}

.prayer-contact {
    font-size: 20px;
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    .prayer-content{
        padding: 112px 16px 0 16px;
    }

    .prayer-heading {
        font-size: 60px;
        padding-bottom: 20px;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #prayer-bg {
        height: 650px;
    }

    .prayer-content{
        padding: 80px 16px 0 16px;
    }
    
    .prayer-heading {
        font-size: 35px;
    }
    
    .prayer-subheading {
        font-size: 20px;
    }

    .prayer-info {
        font-size: 16px;
    }

    .prayer-contact {
        font-size: 16px;
    }
}
