#ph-bg {
    background-color: rgb(255,247,242);
    height: 160em;
    color: rgb(11,64,58);
    font-family: 'Merriweather Sans', sans-serif;
}

.ph-content{
    text-align: center;
    vertical-align: middle;
    padding: 9em 1em 0 1em;
}

.ph-heading {
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 5em;
    color: rgb(11,64,58)
}

.ph-group {
    padding: 0 20%;
    margin-bottom: 8em;
}

.ph-subheading {
    margin-bottom: 3em;
} 

.ph-goal {
    text-align: left;
    margin-bottom: 2em;
}

.ph-interac {
    text-align: left;
    display: flex;
    margin-bottom: 4em;
}

#interac {
    height: 8em;
    margin-right: 3em;
}

.ph-info {
    font-size: 1.5em;
}

.ph-goal h3 {
    margin: 1em 0;
}

.ph-goal ul li {
    font-size: 1.5em;
    margin-bottom: 0.75em;
}

.ph-contact {
    font-size: 25px;
    margin-bottom: 1em;
    line-height: 1.25em;
}

/* 3/4 Page Viewport */
@media only screen and (max-width: 1500px) {
    #ph-bg {
        height: 170em;
    }

    .ph-group {
        padding: 0 15%;
    }
}

/* 1/2 Page Viewport */
@media only screen and (max-width: 1100px) {
    #ph-bg {
        height: 175em;
    }

    .ph-group {
        padding: 0 10%;
    }
}

/* iPad Viewport */
@media only screen and (max-width: 820px) {
    #ph-bg {
        height: 188em;
    }

    .ph-group {
        padding: 0 5%;
    }
}

/* Mobile Viewport */
@media only screen and (max-width: 500px) {
    #ph-bg {
        height: 185em;
    }

    .ph-content{
        padding: 5em 1em 0 1em;
    }
    
    .ph-heading {
        font-size: 2.75em;
    }

    .ph-group {
        padding: 0 1%;
    }
    
    .ph-subheading {
        font-size: 1.25em;
        font-weight: 500;
    }

    .ph-interac {
        text-align: left;
        display: block;
        margin-bottom: 4em;
    }
    
    #interac {
        height: 8em;
        margin-bottom: 1em;
    }

    .ph-info {
        font-size: 1.25em;
    }

    ul li {
        font-size: 1.25em !important;
    }

    .ph-contact {
        font-size: 20px;
    }
}
